<template>
  <div>
    <div id="bg"> </div>
    <Navigation />
    <router-view />
  </div>
</template>

<script>
import Navigation from "@/components/Navigation";

export default {
  name: "App",
  components: {
    Navigation
  }
};
</script>

<style global lang="scss">
@import "./assets/styles/_variables";
@import "~bootstrap/scss/bootstrap";
@import "./assets/styles/_bootswatch";
@import "./assets/styles/custom";

#bg { position: fixed; top: 0; left: 0;width: 100vw; height: 100vh; z-index: -1;}
.bg-black{ background: black!important}
</style>