<template>
  <nav class="navbar navbar-expand-lg navbar-dark bg-dark sticky-top">
    <div class="container">
      <b>
        <a class="navbar-brand" href="/">Swish Astrology</a>
      </b>
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarsExample07"
        aria-controls="navbarsExample07"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarsExample07">
        <ul class="navbar-nav mr-auto"></ul>
        <ul class="navbar-nav">
          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              href="#"
              id="navbarDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >Swishes</a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdown">
              <a v-for="swish in swishes" v-bind:key="swish.id"
                class="dropdown-item"
                :href="'?swish='+swish.id">
                {{ swish.id }}
              </a>
            </div>
          </li>
          <li class="nav-item" v-if="!isLoggedIn">
            <a class="nav-link" href="register">Register</a>
          </li>
          <li class="nav-item" v-if="!isLoggedIn">
            <a class="nav-link" href="dashboard">Login</a>
          </li>
          <li class="nav-item" v-if="isAdmin">
            <a class="nav-link" href="dashboard">Dashboard</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import * as axios from "axios";
export default {
  data() {
    return {
      swishes: []
    };
  },
  computed: {
    isLoggedIn: function() {
      console.log("isloggedin");
      console.log(localStorage.getItem("token"));
      if (localStorage.getItem("token") != null) {
        return true;
      }
      return false;
    },
    isAdmin: function() {
      console.log("Is admin in");
      if (localStorage.getItem("token") != null) {
        console.log("Is logged in");
        var user = this.parseJwt(localStorage.getItem("token"))
        return user.admin;
      } else {
        return false;
      }
    }
  },
  mounted() {
    this.getSwishes();
  },

  methods: {
    parseJwt(token) {
      try {
        var base64Url = token.split(".")[1];
        var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
        var jsonPayload = decodeURIComponent(
          atob(base64)
            .split("")
            .map(function(c) {
              return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join("")
        );

        return JSON.parse(jsonPayload);
      } catch (e) {
        return {};
      }
    },
    getSwishes() {
      console.log("getting swishes in navbar");
      if (localStorage.token != null) {
        axios
          .get(window.env.apiUrl + "swish/", {
            headers: {
              Authorization: "Bearer " + localStorage.token
            }
          })
          .then(response => (this.swishes = response.data))
          .then(() => console.log("DONE"))
          .catch(error => {
            console.error(error);
          });
      }else{
        axios
          .get(window.env.apiUrl + "swishanon/")
          .then(response => (this.swishes = response.data))
          .then(() => console.log("DONE"))
          .catch(error => {
            console.error(error);
          });
      }
    }
  }
};
</script>

<style scoped>
.navbar.bg-dark {
  background-color: rgba(0, 0, 0, 0) !important;
  border: none;
}
</style>