<template>
  <div class="home container">
    <!-- Main Swish Content -->
    <div class="row align-items-center">
      <div class="col-sm">
        <div class="card text-center">
          <div class="card-body">
            <h5 class="card-title">{{this.currentDate}}.</h5>
          </div>
        </div>
      </div>
    </div>
    <!-- /.row -->
    <div v-if="!this.hasData" class="row">
      <div class="col">
        <div class="mx-auto w-50 p-3 text-center">
          <div
            class="spinner-border text-light slow"
            style="width: 3rem; height: 3rem;"
            role="status"
          >
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-sm">
          <div v-if="this.hasData" class="card text-left">
            <div class="card-body">
              <h5 class="card-title text-left">AT THIS MOMENT RIGHT NOW.</h5>
              <p class="card-text text-left">
                <template v-for="(t, i) in this.data.Now">
                  {{t}}
                  <br v-bind:key="i" />
                </template>
              </p>
            </div>
          </div>
        </div>
        <div class="col-sm">
          <div v-if="this.hasData" class="card text-left">
            <div class="card-body">
              <h5 class="card-title text-left">UP TO THE MINUTE GEO POSITIONS.</h5>
              <p class="card-text text-left">
                <template v-for="(t, i) in this.data.Positions">
                  {{t}}
                  <br v-bind:key="i" />
                </template>
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div v-if="this.hasData" class="card text-left">
            <div class="card-body">
              <h5 class="card-title text-left">HIT PARADE. TOP 10.</h5>
              <div class="table-responsive">
                <table class="toptentable-bind">
                  <tr v-for="(e, i) in this.data.TopTen" v-bind:key="i">
                    <td>
                      <p class="card-text text-left">{{ e.Position }}</p>
                    </td>
                    <td>
                      <p class="card-text text-left">{{ e.Value }}</p>
                    </td>
                    <td>
                      <p class="card-text text-left">{{ e.Description }}</p>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Particles } from "particles.ts";
import config from "@/assets/background.json";
import moment from "moment";
export default {
  name: "home",
  data: function() {
    return {
      swishId: this.$route.query.swish,
      data: {},
      currentDate: moment().format("dddd, MMMM Do YYYY, h:mm:ss A")
    };
  },
  methods: {
    addParamsToLocation(params) {
      history.pushState(
        {},
        null,
        this.$route.path +
          "?" +
          Object.keys(params)
            .map(key => {
              return (
                encodeURIComponent(key) + "=" + encodeURIComponent(params[key])
              );
            })
            .join("&")
      );
    }
  },
  computed: {
    // a computed getter
    hasData: function() {
      return !!this.data.Now;
    }
  },
  components: {},
  mounted() {
    setInterval(()=> {
      this.currentDate = moment().format("dddd, MMMM Do YYYY, h:mm:ss A");
    }, 500);
    if (!this.swishId) {
      let defaltSwish = "solo";
      this.addParamsToLocation({ swish: defaltSwish });
      this.swishId = defaltSwish;
    }

    function heartbeat() {
      console.log("In heartbeat");
      clearTimeout(this.pingTimeout);

      // Use `WebSocket#terminate()`, which immediately destroys the connection,
      // instead of `WebSocket#close()`, which waits for the close timer.
      // Delay should be equal to the interval at which your server
      // sends out pings plus a conservative assumption of the latency.
      this.pingTimeout = setTimeout(() => {
        this.terminate();
      }, 30000 + 1000);
    }

    this.$connect(`${env.webSocketUrl}${this.swishId}`);
    this.$options.sockets.onopen = () => {
      console.log("AUTHORIZING CLIENT SIDE")
      this.$socket.send(JSON.stringify({
        type: 'authenticate',
        payload: { token: localStorage.token}
      }))
    };
    this.$options.sockets.onopen = heartbeat;
    this.$options.sockets.onping = heartbeat;
    this.$options.sockets.onclose = function() {
      clearTimeout(this.pingTimeout);
    };
    this.$options.sockets.onmessage = data => {
      data = JSON.parse(data.data)
      try {
        switch(data.type){
          case "meta":
            console.log(data.payload)
            break;
          case "data":
            this.data = JSON.parse(data.payload);
            break;
        }
      } catch (e) {
        console.log(data);
        console.error("Unexpected message from server", e);
      }
    };
    let particles = new Particles("bg", config);
    particles.start();
    document.getElementById("bg").classList.add("bg-black");
  },
  destroyed() {
    console.log("disconnecting");
    vm.$disconnect();
    document.getElementById("bg").classList.remove("bg-black");
  }
};
</script>

<style>
html { font-size: 14px }
.card {
  background-color: rgba(0, 0, 0, 0.1);
  border: none;
}


</style>