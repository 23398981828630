import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/register',
    name: 'register',
    component: () => import(/* webpackChunkName: "about" */ '../views/Register.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue')
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Dashboard.vue'),
    meta: {
      requiresAdmin: true
    }

  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})
function parseJwt (token : any) {
  try{
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    return JSON.parse(jsonPayload);
  }catch(e){
    return {}
  }
}

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAdmin)) {
    if (localStorage.getItem('token') == null) {
      next({
        path: '/login',
        params: { nextUrl: to.fullPath }
      })
    } else {
      var user = parseJwt(localStorage.getItem('token'))
      if((Date.now() >= parseJwt(localStorage.getItem('token')).exp * 1000)){
        localStorage.removeItem('token');
        next({
          path: '/login',
          params: { nextUrl: to.fullPath }
        })
      }
      if(user.admin){
        next()
      }else {
        next({
          path: '/',
          params: { nextUrl: to.fullPath }
        })
      }
    }
  } else {
    next()
  }
})

export default router
