import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'bootstrap'
import jQuery from 'jquery';
//@ts-ignore
import VueNativeSock from 'vue-native-websocket'

Vue.config.productionTip = false

const $ = jQuery;
(<any>window).$ = $;
(<any>window).env = {
  apiUrl: process.env.VUE_APP_API_URL,
  webSocketUrl: process.env.VUE_APP_WS_URL,
  environment: process.env.VUE_APP_ENVIRONMENT,
  defaultSwish: process.env.VUE_APP_DEFAULT_SWISH
}

Vue.use(VueNativeSock, `${(<any>window).env.webSocketUrl}${(<any>window).env.defaultSwish}`, { 
  connectManually: true,
  //store: store
 })

new Vue({
  router,
  store,
  render: (h:any) => h(App)
}).$mount('#app')
